
import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { phoneAndFixedLine, email } from '@/utils/validate'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { FetchSuggestionsCallback } from 'element-ui/types/autocomplete'
import { SupplierDataList } from '@/types/material'
@Component({
  name: 'SuppliermanageAdd'
})
export default class extends Vue {
  private typeList = []
  private formInfo: SupplierDataList = {
    supplierId: '',
    supplierName: '',
    supplierCode: '',
    supplierType: '',
    // 经营范围
    operationScope: '',
    // 供应商介绍
    supplierDesc: '',
    // 供应商级别
    supplierLevel: '',
    // 供应商邮编
    supplierPostal: '',
    // 省
    province: '',
    city: '',
    area: '',
    // 详细地址
    address: '',
    contactList: []
  }

  private rulesForm = {
    supplierName: [
      { required: true, message: '请输入供应商名称', trigger: 'blur' }
    ],
    supplierType: [
      { required: true, message: '请输入供应商类型', trigger: 'change' }
    ]
  }

  private loading = false
  private addrList = [] // 地址数据json
  private addrInfo: Array<string> = [] // 省市区完整数据

  private tableRules = {
    name: [
      { required: true, message: '请输入姓名', trigger: 'change' }
    ],
    telephone: [
      { required: true, message: '请输入电话号码', trigger: 'blur' },
      { validator: phoneAndFixedLine, trigger: ['change', 'blur'] }
    ],
    mail: [
      { validator: email, trigger: ['change', 'blur'] }
    ]
  }

  get supplierId () {
    return this.$route.params.supplierId || ''
  }

  created () {
    this.getConfigList()
    this.getAddrList()
    this.supplierId && this.getDetail()
  }

  // 获取供应商类型
  getConfigList () {
    this.$axios.get(this.$apis.material.selectErpSupplierTypeByPage).then(res => {
      if (res) {
        this.typeList = res.list || []
      }
    })
  }

  // 获取地址信息
  getAddrList () {
    this.$axios.get(this.$apis.common.getRegion).then(res => {
      this.addrList = res || []
    })
  }

  areaChange () {
    if (this.addrInfo.length === 3 && this.addrInfo[2] !== '') {
      this.formInfo.province = this.addrInfo[0] || ''
      this.formInfo.city = this.addrInfo[1] || ''
      this.formInfo.area = this.addrInfo[2] || ''
    } else {
      this.formInfo.province = ''
      this.formInfo.city = ''
      this.formInfo.area = ''
    }
  }

  // 详细地址输入
  addrFetch (queryString: string, cb: FetchSuggestionsCallback) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.AutoComplete',
        'AMap.Geocoder'
      ]
    }).then(() => {
      // 实例化Autocomplete
      const autoComplete = new AMap.Autocomplete({
        city: this.formInfo.city
      })
      autoComplete.search(this.formInfo.area + queryString, (status, result) => {
        const res = result as AMap.Autocomplete.SearchResult
        if (status === 'complete') {
          cb(res.tips)
        }
      })
    })
  }

  // 点击详细地址下拉
  addrChange (data: AMap.Autocomplete.Tip) {
    this.formInfo.address = data.name
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.material.selectErpSupplierBySupplierId,
      {
        supplierId: this.supplierId
      }).then((res) => {
      this.formInfo = res || {}
      this.addrInfo = [res.province, res.city, res.area]
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  add () {
    this.formInfo.contactList.push({
      department: '',
      mail: '',
      name: '',
      post: '',
      telephone: ''
    })
  }

  onDelete (index: number) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.formInfo.contactList.splice(index, 1)
      this.$message.success('删除成功')
    })
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate((valid) => {
      if (valid) {
        if (this.formInfo.contactList.length > 0) {
          (this.$refs.addTable as ElForm).validate((valid) => {
            if (valid) {
              const url = this.supplierId ? this.$apis.material.updateErpSupplier : this.$apis.material.insertErpSupplier
              this.$axios.post(url, this.formInfo).then(() => {
                this.$message.success('保存成功')
                this.$router.back()
              })
            }
          })
        } else {
          this.$message.warning('请添加联系人信息')
        }
      }
    })
  }
}
